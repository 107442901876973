import React, { PureComponent } from 'react';
import { Tooltip } from 'antd';
import {Dialog, ViewTable, Button, Status, Layout} from '@/components';
import { routerYchMap } from '@/router/ych_router';
import { YchApi } from '@/web/api';

export default class AgentPage extends PureComponent<RouterPropsType> {

  private table: ViewTable | null = null
  private reqData = {
    agentName: ''
  }

  render() {

    return (
      <Layout page flexColumn overHidden>
        <Layout.Toolbar title="代理商">
          <Button type="primary" onClick={() => {
            this.props.history.push(routerYchMap['agent.create'].path);
          }}>新增代理商</Button>
          <Button.Refresh onClick={() => {
            this.table?.refresh();
          }}/>
        </Layout.Toolbar>
        <Layout flex1 white padding overHidden>
          <ViewTable
            fetch={(data) => {
              return YchApi.agent_list();
            }}
            pagination={false}
            ref={r => this.table = r}
            columns={[
              {
                title: '编号',
                dataIndex: 'agentId',
                width: 200,
                align: 'center',
              },
              {
                title: '名称',
                dataIndex: 'agentName',
                width: 200,
                render: (v, r) => {
                  return <Button type="link" style={{padding: 0}} onClick={() => {
                    sessionStorage.setItem('$agent_edit_info', JSON.stringify(r));
                    this.props.history.push(routerYchMap['agent.update'].getPath(r.id));
                  }}>{v}</Button>
                }
              },
              {
                title: '状态',
                width: 150,
                align: 'center',
                dataIndex: 'status',
                render: (value) => {
                  return <Status status={value}/>
                }
              },
              {
                title: '级别',
                dataIndex: 'compType',
                render: text => {
                  if (text === '2') {
                    return '市级';
                  }
                  return '县(区)级';
                },
                width: 100,
                align: 'center'
              },
              {
                title: '业务员',
                dataIndex: 'employeeName',
                width: 200
              },
              {
                title: '联系人',
                dataIndex: 'linkman',
                width: 200
              },
              {
                title: '手机',
                dataIndex: 'mobile',
                align: 'center',
                width: 150
              },
              {
                title: '地址',
                dataIndex: 'address',
                width: 300,
                render: (text, record) => <Tooltip title={`${record.provinceName} ${record.cityName} ${record.areaName} ${record.street}`}>
                  <div style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}>{`${record.provinceName} ${record.cityName} ${record.areaName} ${record.street}`}</div>
                </Tooltip>,
              },
              {
                title: '创建时间',
                dataIndex: 'createdAt',
                align: 'center',
                width: 200,
              },
            ]}
            rowKey={row => row.agentId}
            actionButtons={[
              {
                type: 'update',
                onClick: (row) => {
                  sessionStorage.setItem('$agent_edit_info', JSON.stringify(row));
                  this.props.history.push(routerYchMap['agent.update'].getPath(row.id));
                },
              },
              (row) => {
                return {
                  type: row.status === '1' ? 'disable' : 'enable',
                  onClick: (row) => {
                    this.clickStop(row, row.status === '1' ? '0' : '1');
                  }
                };
              },
              // {
              //   type: 'delete',
              //   onClick: (row) => {
              //     this.clickDelete(row);
              //   }
              // }
            ]}
          />
        </Layout>
      </Layout>
    );
  }

  clickStop = (record, status) => {
    const statusDesc = status === '1' ? '启用' : '禁用';
    Dialog.confirm({
      title: '确认',
      content: `确认${statusDesc} ${record.agentName} ?`,
      onOk: async () => {
        YchApi.agent_update({
          status,
          agentId: record.agentId,
        }).then(() => {
          showSuccess.save();
          this.table?.refresh();
        })
      },
    });
  }

  clickDelete = (record) => {
    Dialog.confirm({
      title: '提示',
      content: `确认删除 “${record.agentName}”吗?`,
      onOk: async () => {
        YchApi.agent_delete({
          agentId: record.agentId,
        }).then(() => {
          showSuccess.save();
          this.table?.reset();
        })
      },
    });
  }
}
